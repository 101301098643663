import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { differenceInDays } from 'date-fns'
import { IconRocket } from '@tabler/icons-react'

import Button from '@components/Button'
import { usePopupHandlers } from '@components/Popup'
import PlansListPopup from '@src/pages/Billing/PlansListPopup'

import { useGetTeamSubscription } from '@queries/Billing'
import useGetAccount from '@helpers/hooks/useGetAccount'
import { Role } from '@type/common'

import styles from '../../index.module.css'

export interface TrialCounterProps {}

export const TrialCounter: React.FC<TrialCounterProps> = () => {
  const { t } = useTranslation('common', { keyPrefix: 'trialCounter' })
  const user = useGetAccount()
  const { data: subscription } = useGetTeamSubscription({ enabled: !!user?.team?.subscription })
  const { handleTogglePopup, setIsPopupOpen, isPopupOpen } = usePopupHandlers()

  const diffDays = subscription?.trial_end ? differenceInDays(new Date(subscription?.trial_end), new Date()) : 0

  return (
    <div className="h-12 text-sm sm:text-lg bg-violet-700 text-white flex items-center justify-center gap-2">
      <p>
        <Trans
          i18nKey="trialLeftMessage"
          count={diffDays < 0 ? 0 : diffDays + 1}
          t={t}
          components={[<span className={styles.trialCounter} key={0} />]}
        />
      </p>
      {user?.role === Role.admin && (
        <Button onClick={handleTogglePopup} type="outlined" className="text-sm sm:text-base px-1 sm:px-4">
          {t('upgradeLabel')} <IconRocket className="h-5 w-5" />
        </Button>
      )}
      {isPopupOpen && <PlansListPopup isPopupVisible={isPopupOpen} setIsPopupVisible={setIsPopupOpen} />}
    </div>
  )
}

export default TrialCounter
